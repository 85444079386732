.App {
    text-align: center;
    font-family: Arial, sans-serif;
    background: #f5f5f5;
    padding: 20px;
  }
  
  .container {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: flex-start;
    gap: 20px;
    flex-wrap: wrap;
    max-width: 900px;
    margin: 0 auto;
  }
  
  .gif-container {
    flex: 1;
    max-width: 300px;
  }
  
  .responsive-gif {
    width: 100%;
    height: auto;
    border-radius: 10px;
    border: 1px solid #ddd;
  }
  
  .checkout-container {
    flex: 1;
    max-width: 400px;
    background: #ffffff;
    padding: 20px;
    border-radius: 10px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  }
  
  h1 {
    margin-bottom: 10px;
  }
  
  p {
    margin-bottom: 20px;
  }
  
  @media (max-width: 600px) {
    .container {
      flex-direction: column;
      align-items: center;
    }
  
    .checkout-container, .gif-container {
      max-width: 100%;
    }
  }
  