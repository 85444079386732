body {
    margin: 0;
    padding: 0;
    font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial,
      sans-serif;
    background-color: #f5f5f5;
    color: #333;
  }
  
  * {
    box-sizing: border-box;
  }
  